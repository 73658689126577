import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RodzajSelekcji } from '../common/rodzaj-selekcji';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RodzajSelekcjiService {

  private rodzajSelekcjiServiceUrl =  environment.pszokApi + '/slowniki/findPrzyjmowaneSelekcje';

  constructor(private httpClient: HttpClient) { }

  getRodzajeSelekcji(): Observable<RodzajSelekcji[]> {
    return this.httpClient.get<RodzajSelekcji[]>(this.rodzajSelekcjiServiceUrl);
  }
}


