<div class="main-content">
    <div class="section-content section-content-p30">
        <div class="container-fluid">
            <div>
                <h1>Ważenia zakończone</h1><p>Ostatnia modyfikacja:{{ lastUpdateTime | date: 'yyyy-MM-dd HH:mm:ss' }}</p>
                <h3>{{ currentFiltrDzien  }} {{ currentKomorkaOrganizacyjnaSymbol  }} 
                    <a routerLink="/przyjecie/{{ currentKomorkaOrganizacyjnaSymbol  }}" class="btn btn-primary">Dodaj</a>
                    <a routerLink="/rozpoczete/{{ currentKomorkaOrganizacyjnaSymbol  }}/{{ currentFiltrDzien }}" class="btn btn-primary">Ważenia rozpoczęte</a>
                </h3>                
            </div>
            <div class="row table-responsive">                
                <table class="table table-striped table-hover">

                    <thead class="thead-dark">
                        <tr>                                                                                   
                            <th>Adres</th>                            
                            <th>Rodzaj selekcji</th> 
                            <th>Waga kg</th>  
                            <th>Operator</th>                          
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let tempWazenie of wazenia">                                      
                           
                            <td  class="col-md-6"  style="padding-left: 5px;">  
                                <div class="row">                                    
                                    <div class="col-md-4" style="color: brown;">{{ tempWazenie.gmina['nazwaGminy'] }} </div>                        
                                    <div class="col-md-4">{{ tempWazenie.ulica}} {{ tempWazenie.nrDomu}}</div>                                    
                                    <div class="col-md-4">{{ tempWazenie.miejscowosc}}</div>
                                </div>                                                                                                                            
                            </td>                            
                            <!-- <td class="col-md-3">{{ tempWazenie.rodzajSelekcji.nazwa}}</td> -->
                            <td class="col-md-3"><div *ngIf="tempWazenie.rodzajSelekcji != null">{{ tempWazenie.rodzajSelekcji.nazwa}}</div><a routerLink="/przyjecie/{{ currentKomorkaOrganizacyjnaSymbol  }}/{{ tempWazenie.id }}" class="btn btn-primary">Edytuj</a></td>
                            <td class="col-md-3" style="padding-right: 2px;">{{ tempWazenie.waga}}</td> 
                            <td class="col-md-2" style="padding-right: 2px;">
                                <div class="col-md-6" >{{ tempWazenie.uwagi }}</div>
                                <div class="col-md-6" style="color: brown;">{{ tempWazenie.zmodyfikowal }}</div>                                
                            </td>                                                      
                        </tr>

                    </tbody>
                </table>
            </div>
             <!-- begin footer -->
             <div class="footer-pagination">
                <div class="row">
                
                  
                        <div class="col-xs-9 col-sm-9 col-md-9">
                        
                            <ngb-pagination [(page)]="thePageNumber"
                                            [pageSize]="thePageSize"
                                            [collectionSize]="theTotalElements"
                                            [maxSize]="3"
                                            [boundaryLinks]="true"
                                            (pageChange)="listWazenia()">

                            </ngb-pagination>

                        </div>

                        <div class="col-xs-3 col-sm-3 col-md-3 mt-2" style="text-align: right;">
                            <span class="mr-2">Page Size</span>

                            <select (change)="updatePageSize($event.target.value)">                                
                                <option selected="true">5</option>
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>

                    </div>
                   
            </div>
            <!-- end footer -->
        </div>
    </div>
</div>




