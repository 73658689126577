<div class="menu-sidebar-content js-scrollbar1">
    <nav class="navbar-sidebar">
        <ul class="list-unstyled navbar-list">

            <li *ngFor="let tempPszok of pszoki">

                <a routerLink="/wazenia/{{ tempPszok.symbol }}/{{ filtrDzien }}" routerLinkActive="active-link">
                    {{ tempPszok.symbol }}
                </a>

            </li>            
        </ul>
        <input #myInput type="text" [(value)]="filtrDzien"
            placeholder="Wybierz dzień"
            class="au-input au-input-sm"
            (keyup.enter)="doSearch(myInput.value)"  />

        <button (click)="doSearch(myInput.value)" class="au-btn-submit">
            Szukaj
        </button>
        
        
    </nav>
</div>
