<div class="main-content page-m">
    <div class="section-content section-content-p30">
        <div class="container-fluid">
            <div style="margin-bottom: 30px;">
                <h1>Rejestracja - dzien {{ currentFiltrDzien }}</h1>
                <h3>{{ currentKomorkaOrganizacyjnaSymbol  }} {{ rocordId }}
                    <a routerLink="/rozpoczete/{{ currentKomorkaOrganizacyjnaSymbol  }}/{{ currentFiltrDzien }}" class="btn btn-primary">Ważenia rozpoczęte</a>
                    <a routerLink="/wazenia/{{ currentKomorkaOrganizacyjnaSymbol  }}/{{ currentFiltrDzien }}" class="btn btn-secondary">Ważenia zakończone</a>
                </h3>
            </div>
            

            <form [formGroup]="przyjecieFormGroup" (ngSubmit)="onSubmit($event)">
                <div formGroupName="szukaj" class="form-area">
                    <h3>Szukaj</h3>
                   

                    <div class="row">
                        <div class="col-md-2"> <label>Miejscowość</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="szukajMiejscowosc" type="text" (keyup)="handleSearchAdres()">                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2"> <label>Ulica</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="szukajUlica" type="text" (keyup)="handleSearchAdres()">                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2"> <label>Nr domu</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="szukajNrDomu" type="text" (keyup)="handleSearchAdres()">                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 pt-3" *ngFor="let s of listaAdresow">
                            <button type="button" name="complete" (click)="completeAdres(s)" class="btn btn-primary btn-sm">{{ s.miejscowosc }} {{ s.ulica }} {{ s.nrDomu }}</button>                          
                        </div>
                    </div>

                    


                </div>


                <!-- mieszkaniec -->
                <div formGroupName="mieszkaniec" class="form-area">

                    <h3>Mieszkaniec</h3>
                    <div class="row">
                        <div class="col-md-2"> <label>Gmina</label></div>
                        <div class="col-md-9">                            
                            <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                <span *ngFor="let gmina of gminy">
                                    <input  type="radio" class="btn-check" formControlName="gmina" id="{{ gmina.id }}" value="{{gmina.nazwaGminy}}" autocomplete="off">
                                    <label class="btn btn-outline-primary" for="{{ gmina.id }}">{{gmina.nazwaGminy}}</label>

                                </span>
                            </div>                                                                                       
                        </div>

                        <div class="col-md-2"> <label>Miejscowość</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="miejscowosc" type="text" (change)="onChangeMieszkaniec()">

                                <div *ngIf="miejscowosc.invalid && (miejscowosc.dirty || miejscowosc.touched)" class="alert alert-danger mt-1">

                                    <div *ngIf="miejscowosc.errors.required || miejscowosc.errors.notOnlyWhitespace">
                                        Uzupełnij nazwę miejcowości
                                    </div>

                                    <div *ngIf="miejscowosc.errors.minlength">
                                        Pole miejscowość powinno być co najmniej 3 znakowe
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="col-md-2"> <label>Ulica</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="ulica" type="text" (change)="onChangeMieszkaniec()">

                                <div *ngIf="ulica.invalid && (ulica.dirty || ulica.touched)" class="alert alert-danger mt-1">

                                    <div *ngIf="ulica.errors.required || ulica.errors.notOnlyWhitespace">
                                        Uzupełnij nazwę ulicy - kreska jeśli brak
                                    </div>

                                    <div *ngIf="ulica.errors.minlength">
                                        Nazwa ulicy musi zawierać co najmniej 1 znak
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-2"> <label>Nr domu</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="nrDomu" type="text" (change)="onChangeMieszkaniec()">

                                <div *ngIf="nrDomu.invalid && (nrDomu.dirty || nrDomu.touched)" class="alert alert-danger mt-1">

                                    <div *ngIf="nrDomu.errors.required || nrDomu.errors.notOnlyWhitespace">
                                        Pole nr domu jest wymagane
                                    </div>

                                    <div *ngIf="nrDomu.errors.minlength">
                                        Nr domu  musi zawierać co najmniej 1 znak                                    
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-2"> <label>Właściciel</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="wlasciciel" type="text">                               
                            </div>                            
                            <div class="alert alert-warning mt-1">                                
                                    Właściciel wg. deklaracji.<br/>
                                    Dane nie zostaną zapamiętane.                                
                            </div>
                        </div>
                        <div class="col-md-2"> <label>Firma</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="czyFirma" type="text" hidden="true">                               
                            </div>                            
                            <div class="alert alert-danger mt-1"  *ngIf="czyFirma.value == true">                                
                                    Uwaga pod tym adresem występuje Firma!                                    
                            </div>
                            <div class="alert alert-info mt-1"  style="background-color: lightgreen;color: black;" *ngIf="czyFirma.value == false">                                
                                Mieszkaniec                                    
                            </div>
                        </div>

                        <div class="col-md-2"> <label>Zestawienie ostatnie 365 dni</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <ul>
                                    <li *ngFor="let poz of listaSumaWazen">
                                        {{poz.rodzajSelekcji}} - {{poz.sumaWagi}} kg ({{poz.liczbaWazen}} zapisów)
                                    </li>
                                </ul>    

                            </div>                            
                            
                        </div>

                    </div>                   
                </div>


                <!-- Selekcja -->
                <div formGroupName="selekcja" class="form-area">
                    <h3>Selekcja</h3>

                   

                    <div class="row">

                        <div class="col-md-2"> <label>Gmina</label></div>
                        <div class="col-md-9"> 
                            <div class="form-check" *ngFor="let sel of rodzajeSelekcji">
                                <input class="form-check-input" type="radio" formControlName="rodzajSelekcji" id="rs{{ sel.id }}" value="{{sel.nazwa}}" (click)="onChangeSelekcja()" >
                                <label class="form-check-label" for="rs{{ sel.id }}">
                                    {{sel.nazwa}}
                                </label>
                              </div>                                                                                                                
                        </div>

                        <div class="col-md-2"> <label>Data</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="data" type="text" (click)="onChangeSelekcja()" >

                                <div *ngIf="data.invalid && (data.dirty || data.touched)" class="alert alert-danger mt-1">
                                    <div *ngIf="data.errors.pattern">
                                        Nieprawidłowy format dla daty ważenia (rrrr-MM-dd)
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-2"> <label>Brutto</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="brutto" (click)="onChangeSelekcja()"  type="text">

                                <div *ngIf="brutto.invalid && (brutto.dirty || brutto.touched)" class="alert alert-danger mt-1">

                                    <div *ngIf="brutto.errors.required || brutto.errors.pattern">
                                        Nieprawidłowy format dla wagi
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-2"> <label>Tara</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="tara" (click)="onChangeSelekcja()"  type="text">

                                <div *ngIf="tara.invalid && (tara.dirty || tara.touched)" class="alert alert-danger mt-1">

                                    <div *ngIf="tara.errors.required || tara.errors.pattern">
                                        Nieprawidłowy format dla wagi
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2"> <label>Netto</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="netto" (click)="onChangeSelekcja()"  type="text">

                                <div *ngIf="netto.invalid && (netto.dirty || netto.touched)" class="alert alert-danger mt-1">

                                    <div *ngIf="netto.errors.required || netto.errors.pattern">
                                        Podaj wagę selekcji
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>                                      
                <!-- Uwagi -->
                <div formGroupName="notatka" class="form-area">
                    <h3>Notatka</h3>

                    <div class="row">
                        <div class="col-md-2"> <label>Uwagi</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="uwagi" type="text" >                                
                            </div>
                        </div>                        
                    </div>

                    <div class="row">
                        <div class="col-md-2"> <label>Deklaracja</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="deklaracja" type="text">                                
                            </div>
                        </div>                        
                    </div>

                    <div class="row">
                        <div class="col-md-2"> <label>Id</label></div>
                        <div class="col-md-9">
                            <div class="input-space">
                                <input formControlName="id" type="text">                                
                            </div>
                        </div>                        
                    </div>

                </div>  
                
                <!-- submit button-->
                <div class="text-center">
                    <span *ngIf="this.isBtn1LabelVisible">
                        <button type="submit" class="btn btn-info" name="save_and_close">{{btn1Label}}</button>
                    </span> 
                    <span *ngIf="this.isBtn2LabelVisible">
                        <button type="submit" class="btn btn-info" name="save_and_continue">{{btn2Label}}</button>
                    </span>                   
                    <span *ngIf="false">
                        <button type="submit" class="btn btn-info" name="reset">Wyczyść</button>    
                    </span>
                    
                </div> 
                <div class="text-left">
                    <div  class="alert alert-warning mt-1">
                        <ul>
                            <li>OP - Żużyte opony</li>
                            <li>B - Odpady ulegające biodegradacji</li>
                            <li>MA - Opakowania z papieru i tektury</li>
                            <li>ME - Opakowania z metali</li>
                            <li>G - Odpady wielkogabarytowe</li>
                            <li>O - Niesegregowane (zmieszane) odpady komunalne</li>                                    
                            <li>P - Opakowania z tworzyw sztucznych i metali</li>
                            <li>SB - Szkło białe</li>
                            <li>SM - Opakowania ze szkła oraz z papieru i tektury</li>
                            <li>E - Zuzyty sprzęt elektryczny i elektroniczny</li>
                            <li>S - Opakowania ze szkła</li>
                            <li>D - Inne niewymienione frakcje zbierane w sposób selektywny</li>
                            <li>MB - Opakowania z papieru i tektury oraz odpady ulegające biodegradacji</li>
                            <li>NS - Zmieszane niesegregowane</li>
                            <li>GR - Gruz</li>                                    
                        </ul>
                    </div>
                </div>                 
            </form>
        </div>
    </div>
</div>

