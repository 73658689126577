import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { AppMenuComponent } from './app-menu.component';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FallbackComponent } from './fallback.component';
import { ShouldLoginComponent } from './should-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GminaMenuComponent } from './components/gmina-menu/gmina-menu.component';
import { PszokMenuComponent } from './components/pszok-menu/pszok-menu.component';
import { WazenieListComponent } from './components/wazenie-list/wazenie-list.component';
import { RozpoczeteListComponent } from './components/rozpoczete-list/rozpoczete-list.component';
import { PrzyjecieComponent } from './components/przyjecie/przyjecie.component';

const routes: Routes = [
  {path: 'wazenia/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: WazenieListComponent},
  {path: 'rozpoczete/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: RozpoczeteListComponent},
  {path: 'wazenia/:komorkaOrganizacyjnaSymbol', component: WazenieListComponent},
  {path: 'przyjecie/:komorkaOrganizacyjnaSymbol', component: PrzyjecieComponent},
  {path: 'przyjecie/:komorkaOrganizacyjnaSymbol/:id', component: PrzyjecieComponent},
  {path: 'ustawienia', component: PszokMenuComponent},
  {path: '', redirectTo: '/wazenia/PSZOK1', pathMatch: 'full'},
  {path: '**', redirectTo: '/wazenia/PSZOK1', pathMatch: 'full'}
];

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    FallbackComponent,
    ShouldLoginComponent,
    GminaMenuComponent,
    PszokMenuComponent,
    WazenieListComponent,
    RozpoczeteListComponent,
    PrzyjecieComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule.forRoot(),
    RouterModule.forRoot([
    // { path: '', redirectTo: 'basics/home', pathMatch: 'full' },
    {path: 'wazenia/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: WazenieListComponent},
    {path: 'rozpoczete/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: RozpoczeteListComponent},
    {path: 'wazenia/:komorkaOrganizacyjnaSymbol', component: WazenieListComponent},
    {path: 'przyjecie/:komorkaOrganizacyjnaSymbol', component: PrzyjecieComponent},
    {path: 'przyjecie/:komorkaOrganizacyjnaSymbol/:id', component: PrzyjecieComponent},
    {path: 'ustawienia', component: PszokMenuComponent},

    // Note: this way of module loading requires this in your tsconfig.json: "module": "esnext"
    // { path: 'basics', loadChildren: () => import('./feature-basics/basics.module').then(m => m.BasicsModule) },
    // { path: 'extras', loadChildren: () => import('./feature-extras/extras.module').then(m => m.ExtrasModule) },
    // { path: 'should-login', component: ShouldLoginComponent },
    // { path: '**', component: FallbackComponent },
    {path: 'fallback', component: FallbackComponent },
    {path: '', redirectTo: '/wazenia/PSZOK1', pathMatch: 'full'},
    {path: '**', redirectTo: '/wazenia/PSZOK1', pathMatch: 'full'}
], {  relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top'  })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
