import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { BehaviorSubject } from 'rxjs/index/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
// import { map } from 'rxjs/internal/operators/map';
import { Subject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { KomorkaOrganizacyjna } from '../common/komorka-organizacyjna';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KomorkaOrganizacyjnaService {
  
  private komorkaOrganizacyjnaServiceUrl = environment.pszokApi +  '/slowniki/findPszok';

  filtrDzienSubject: Subject<string> = new BehaviorSubject<string>(new Date().toISOString().substring(0,10));
  filtrPszok: Subject<string> = new BehaviorSubject<string>("PSZOK1");

  constructor(private httpClient: HttpClient) { }
  getPszoki(): Observable<KomorkaOrganizacyjna[]> {
    return this.httpClient.get<KomorkaOrganizacyjna[]>(this.komorkaOrganizacyjnaServiceUrl);
  }

  updateFilterDzien(filtrDzien:string){   
    this.filtrDzienSubject.next(filtrDzien);
  }

  updateFilterPszok(filtrPszok:string){   
    this.filtrPszok.next(filtrPszok);
  }
}
