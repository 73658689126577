import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Gmina } from 'src/app/common/gmina';
import { Deklaracja } from 'src/app/common/deklaracja';
import { RodzajSelekcjiService } from 'src/app/services/rodzaj-selekcji.service';
import { DeklaracjeService } from 'src/app/services/deklaracje.service';
import { GminaService } from 'src/app/services/gmina.service';
import { RodzajSelekcji } from 'src/app/common/rodzaj-selekcji';
import { Przyjecie } from 'src/app/common/przyjecie';
import { WazenieService } from 'src/app/services/wazenie.service';
import { OdpadySelektywnieZebrane } from 'src/app/common/odpady-selektywnie-zebrane';
import { SumaWazen } from 'src/app/common/suma-wazen';

@Component({
  selector: 'app-przyjecie',
  templateUrl: './przyjecie.component.html',
  styleUrls: ['./przyjecie.component.css']
})
export class PrzyjecieComponent implements OnInit {

  przyjecieFormGroup: UntypedFormGroup;

  gminy: Gmina[] = [];
  rodzajeSelekcji: RodzajSelekcji[] = [];
  listaAdresow: Deklaracja[] = [];
  listaSumaWazen: SumaWazen[] = [];

  currentKomorkaOrganizacyjnaSymbol: string = 'PSZOK1';
  currentFiltrDzien: string = new Date().toISOString().substring(0,10);

  odDnia: string; //Do sumowania kg dla adresu
  doDnia: string; //Do sumowania kg dla adresu

  rocordId: number;
  record: OdpadySelektywnieZebrane;

  btn1Label: string = "Zakończ";
  btn2Label: string = "Bufor";

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private gminaService: GminaService,
    private deklaracjeService: DeklaracjeService,
    private rodzajSelekcjiService: RodzajSelekcjiService,
    private wazeniaService: WazenieService
    ) { }
    

  ngOnInit(): void {

    const hasKomorkaOrganizacyjnaSymbol: boolean = this.route.snapshot.paramMap.has('komorkaOrganizacyjnaSymbol');
    if (hasKomorkaOrganizacyjnaSymbol) {      
      this.currentKomorkaOrganizacyjnaSymbol = this.route.snapshot.paramMap.get('komorkaOrganizacyjnaSymbol');
    }
    else {      
      this.currentKomorkaOrganizacyjnaSymbol = 'PSZOK1';
    }  

    const hasFiltrDzien: boolean = this.route.snapshot.paramMap.has('filtrDzien');
    if (hasFiltrDzien) {      
      this.currentFiltrDzien = this.route.snapshot.paramMap.get('filtrDzien');
    }
    else {      
      this.currentFiltrDzien = new Date().toISOString().substring(0,10);
    }

    //Wyznaczenie dat do sumowania wag - 365dni
    var obliczenieDaty = new Date(); //Dzis
    this.doDnia = obliczenieDaty.toISOString().substring(0,10);
    obliczenieDaty.setDate(obliczenieDaty.getDate()-365);
    this.odDnia = obliczenieDaty.toISOString().substring(0,10);
    console.log("Zadres dn: " + this.odDnia + " " + this.doDnia);


    const hasRecordId: boolean = this.route.snapshot.paramMap.has('id');
    if (hasRecordId) {      
      this.rocordId =+this.route.snapshot.paramMap.get('id');
    }
    else {      
      this.rocordId = null;
    }



    this.przyjecieFormGroup = this.formBuilder.group({
      szukaj: this.formBuilder.group({
        szukajAdresu: new UntypedFormControl(''),
        szukajMiejscowosc: new UntypedFormControl(''),
        szukajUlica: new UntypedFormControl(''),
        szukajNrDomu: new UntypedFormControl(''),
      }),
      mieszkaniec: this.formBuilder.group({        
        gmina: new UntypedFormControl('', 
                              [Validators.required                                
                              ]),
        miejscowosc: new UntypedFormControl('', 
                              [Validators.required, 
                               Validators.minLength(3)
                              ]),

        ulica:  new UntypedFormControl('', 
                              [Validators.required, 
                               Validators.minLength(1)
                              ]),
                               
        nrDomu: new UntypedFormControl('',
                              [Validators.required,
                                Validators.minLength(1)
                              ]),
        wlasciciel: new UntypedFormControl({value: '', disabled: true}),
        czyFirma: new UntypedFormControl({value: '', disabled: true})
      }),
      selekcja: this.formBuilder.group({
        data: new UntypedFormControl(this.currentFiltrDzien,[Validators.pattern("^[0-9][0-9][0-9][0-9]\-[0-9][0-9]\-[0-9][0-9]$")]),
        rodzajSelekcji: new UntypedFormControl(''),
        brutto: new UntypedFormControl('',[Validators.pattern("^[0-9]*$")]),
        tara: new UntypedFormControl('',[Validators.pattern("^[0-9]*$")]),
        netto: new UntypedFormControl('',[Validators.pattern("^[0-9]*$")]),
      }),
      notatka: this.formBuilder.group({
        uwagi: new UntypedFormControl(''),
        deklaracja: new UntypedFormControl({value: '', disabled: true}),
        id: new UntypedFormControl({value: '', disabled: true})
      }),      
    });
    

    this.gminaService.getObslugiwaneGminy().subscribe(
      data => {        
        this.gminy = data;
      }
    );


    this.rodzajSelekcjiService.getRodzajeSelekcji().subscribe(
      data => {    
        this.rodzajeSelekcji = data;
      }
    );

    if(this.rocordId != null)
      this.wazeniaService.getWazenieById(this.rocordId).subscribe(
        data => {        
          this.record = data;

          if(this.record != null){
            this.przyjecieFormGroup.get('mieszkaniec.gmina').setValue(this.record.gmina.nazwaGminy);
            this.przyjecieFormGroup.get('mieszkaniec.miejscowosc').setValue(this.record.miejscowosc);
            this.przyjecieFormGroup.get('mieszkaniec.ulica').setValue(this.record.ulica);
            this.przyjecieFormGroup.get('mieszkaniec.nrDomu').setValue(this.record.nrDomu);
            this.przyjecieFormGroup.get('mieszkaniec.wlasciciel').setValue(this.record.wlasciciel)

            if(this.record.rodzajSelekcji == null)
              this.przyjecieFormGroup.get('selekcja.rodzajSelekcji').setValue('');
            else
              this.przyjecieFormGroup.get('selekcja.rodzajSelekcji').setValue(this.record.rodzajSelekcji.nazwa);

            this.przyjecieFormGroup.get('selekcja.brutto').setValue(this.record.brutto);
            this.przyjecieFormGroup.get('selekcja.tara').setValue(this.record.tara);
            this.przyjecieFormGroup.get('selekcja.netto').setValue(this.record.waga);

            this.przyjecieFormGroup.get('notatka.uwagi').setValue(this.record.uwagi);
            this.przyjecieFormGroup.get('notatka.deklaracja').setValue(this.record.deklaracja);
            this.przyjecieFormGroup.get('notatka.id').setValue(this.record.id);
          }else{
            this.rocordId = null;          
          }        
        }
      );
    
    this.updateButtons();

  }

  get szukajAdresu() { return this.przyjecieFormGroup.get('szukaj.szukajAdresu'); }
  get szukajMiejscowosc() { return this.przyjecieFormGroup.get('szukaj.szukajMiejscowosc'); }
  get szukajUlica() { return this.przyjecieFormGroup.get('szukaj.szukajUlica'); }
  get szukajNrDomu() { return this.przyjecieFormGroup.get('szukaj.szukajNrDomu'); }
  

  get gmina() { return this.przyjecieFormGroup.get('mieszkaniec.gmina'); }
  get miejscowosc() { return this.przyjecieFormGroup.get('mieszkaniec.miejscowosc'); }
  get ulica() { return this.przyjecieFormGroup.get('mieszkaniec.ulica'); }
  get nrDomu() { return this.przyjecieFormGroup.get('mieszkaniec.nrDomu'); }
  get wlasciciel() { return this.przyjecieFormGroup.get('mieszkaniec.wlasciciel'); }
  get czyFirma() { return this.przyjecieFormGroup.get('mieszkaniec.czyFirma'); }

  get rodzajSelekcji() { return this.przyjecieFormGroup.get('selekcja.rodzajSelekcji'); }
  get data() { return this.przyjecieFormGroup.get('selekcja.data'); }
  get brutto() { return this.przyjecieFormGroup.get('selekcja.brutto'); }
  get tara() { return this.przyjecieFormGroup.get('selekcja.tara'); }
  get netto() { return this.przyjecieFormGroup.get('selekcja.netto'); }

  get uwagi() { return this.przyjecieFormGroup.get('notatka.uwagi'); }
  get deklaracja() { return this.przyjecieFormGroup.get('notatka.deklaracja'); }
  

  nettoIsValid() {
    return !isNaN(this.przyjecieFormGroup.get('selekcja.netto').value); 
  }
  bruttoIsValid() {
    return !isNaN(this.przyjecieFormGroup.get('selekcja.brutto').value); 
  }
  rodzajSelekcjiIsValid(){
    if(this.przyjecieFormGroup.get('selekcja.rodzajSelekcji').value === ""){
      return false;
    }else{
      return true;
    };
  }

  //Czy można zakończyć obsługę klienta
  disableSaveAndClose(){    
    if(this.rodzajSelekcjiIsValid && this.nettoIsValid){
      return false;
    }else{
      return true;
    }    
  }

  //Czy zapisać klienta do bufora - musimy znać tylko brutto
  disableSaveAndContinue(){
    if(this.bruttoIsValid){
      return false;
    }else{
      return true;
    }    
  }



  onChangeSelekcja(){    
    this.updateFormFields();
  }

  onChangeMieszkaniec(){    
    this.updateFormFields();
    this.resetSzukajInfo();
  }

  private updateFormFields(){
    this.obliczNetto();
    this.updateButtons();
    
  }

  private resetSzukajInfo(){
    this.przyjecieFormGroup.get('mieszkaniec.wlasciciel').setValue('');
    this.przyjecieFormGroup.get('mieszkaniec.czyFirma').setValue('');
    this.przyjecieFormGroup.get('notatka.deklaracja').setValue('');
  }

  onChangeBrutto(){    
    this.obliczNetto();
    this.updateButtons();
  }

  onChangeTara(){    
    this.obliczNetto();
    this.updateButtons();    
  }

  onChangeNetto(){    
    this.obliczNetto();
    this.updateButtons();
  }

  isValidNetto(){
    if(this.przyjecieFormGroup.get('selekcja.netto').value == '' || isNaN(this.przyjecieFormGroup.get('selekcja.netto').value))
      return false;
    else
      return true;
  }

  isValidBrutto(){
    if(this.przyjecieFormGroup.get('selekcja.brutto').value == '' || isNaN(this.przyjecieFormGroup.get('selekcja.brutto').value))
      return false;
    else
      return true;
  }

  isValidTara(){
    if(this.przyjecieFormGroup.get('selekcja.tara').value == '' || isNaN(this.przyjecieFormGroup.get('selekcja.tara').value))
      return false;
    else
      return true;
  }

  isValidMieszkaniec(){   
    if(this.przyjecieFormGroup.get('mieszkaniec.gmina').value == '') return false;
    if(this.przyjecieFormGroup.get('mieszkaniec.miejscowosc').value == '') return false; 
    if(this.przyjecieFormGroup.get('mieszkaniec.nrDomu').value == '') return false;

    return true;  
  }

  isValidSelekcja(){    
    if(this.przyjecieFormGroup.get('selekcja.rodzajSelekcji').value == '') return false; 

    return true;  
  }

  onChangeRodzajSelekcji(){
    this.updateButtons();
  }

  obliczNetto(){
   
    var brutto = ConvertStringToNumber(this.przyjecieFormGroup.get('selekcja.brutto').value); 
    var tara = ConvertStringToNumber(this.przyjecieFormGroup.get('selekcja.tara').value);
    var netto = ConvertStringToNumber(this.przyjecieFormGroup.get('selekcja.netto').value);
    
    
    if(isNaN(netto)){
      //Próbujemy obliczyć netto z butto-tara
      if(isNaN(tara) || isNaN(brutto)){
        //Nie da się obliczyć
        this.przyjecieFormGroup.get('selekcja.netto').setValue('');      
      }else{
        //Obliczamy brutto-tara
        var result:number|string=+ brutto - (+tara);
        this.przyjecieFormGroup.get('selekcja.netto').setValue(result);
      }
    }else{
      //Mamy netto - nie ma uzupełnione brutto
      if(isNaN(brutto)){
        this.przyjecieFormGroup.get('selekcja.brutto').setValue(netto);  
        this.przyjecieFormGroup.get('selekcja.tara').setValue('0');
        brutto=netto;
        tara=0;
      }

      if(isNaN(tara)){
        this.przyjecieFormGroup.get('selekcja.brutto').setValue(netto);  
        this.przyjecieFormGroup.get('selekcja.tara').setValue('0');        
      }


    }
      

    
    console.log(`obliczNetto() ${brutto} - ${tara}`);

    

    
  }

  isBtn1LabelVisible:boolean=true;
  isBtn2LabelVisible:boolean=false;

  updateButtons(){   
    console.log("updateButtons()");
      this.btn1Label="??";
      

      //Przycisk 1
      if(this.isValidNetto() && this.isValidSelekcja() && this.isValidMieszkaniec()){
        this.btn1Label="Zapisz";      
        this.isBtn1LabelVisible=true;
      }else if(this.isValidBrutto() && this.isValidMieszkaniec()){
        this.btn1Label="Do kolejki";        
        this.isBtn1LabelVisible=true;
      }else{
        this.btn1Label="Zapisz";
        this.isBtn1LabelVisible=false;
      }

      //Przycisk 2
      if(this.isValidNetto() && this.isValidSelekcja() && this.isValidMieszkaniec()){
        this.btn2Label="Zapisz i do kolejnego ważenia";        
        this.isBtn2LabelVisible=true;      
      }else{
        this.btn2Label="Zapisz";
        this.isBtn2LabelVisible=false;
      }
        

   
  }
  
  onSubmit(event) {

    console.log(event.submitter.name); 

   
    
    if (this.przyjecieFormGroup.invalid) {
      this.przyjecieFormGroup.markAllAsTouched();    
      return;
    }

    let przyjecie=new Przyjecie();
    przyjecie.komorkaOrganizacyjna = this.currentKomorkaOrganizacyjnaSymbol;
    przyjecie.gmina=this.przyjecieFormGroup.get('mieszkaniec.gmina').value; 
    przyjecie.miejscowosc=this.przyjecieFormGroup.get('mieszkaniec.miejscowosc').value; 
    przyjecie.ulica=this.przyjecieFormGroup.get('mieszkaniec.ulica').value; 
    przyjecie.nrDomu=this.przyjecieFormGroup.get('mieszkaniec.nrDomu').value; 
    przyjecie.rodzajSelekcji=this.przyjecieFormGroup.get('selekcja.rodzajSelekcji').value;
    przyjecie.brutto= this.przyjecieFormGroup.get('selekcja.brutto').value; 
    przyjecie.tara=this.przyjecieFormGroup.get('selekcja.tara').value; 
    przyjecie.waga=this.przyjecieFormGroup.get('selekcja.netto').value;
    przyjecie.uwagi=this.przyjecieFormGroup.get('notatka.uwagi').value; 
    przyjecie.deklaracja=this.przyjecieFormGroup.get('notatka.deklaracja').value; 
    przyjecie.zamieszkale=true;
    przyjecie.data=this.przyjecieFormGroup.get('selekcja.data').value;
    if(this.przyjecieFormGroup.get('mieszkaniec.czyFirma').value === true)
      przyjecie.wlasciciel=this.przyjecieFormGroup.get('mieszkaniec.wlasciciel').value; 

    if( event.submitter.name == "save_and_close" ){ 
      console.log('save_and_close'); 
      przyjecie.zapiszKontynuuj = false;

    }
    else if( event.submitter.name == "save_and_continue"){
       console.log('save_and_continue');
       przyjecie.zapiszKontynuuj = true; 
    }
   
    
    

    // console.log(this.przyjecieFormGroup.get('notatka.id').value);
    if(this.przyjecieFormGroup.get('notatka.id').value === ""){
      console.log("Brak ID")
      przyjecie.id=null;
    }else{
      przyjecie.id=this.przyjecieFormGroup.get('notatka.id').value;
      console.log("Id=" + przyjecie.id);
    }
    

    //  console.log(JSON.stringify(przyjecie));
    

    

    if(przyjecie.id == null){
      console.log("Nowy rekord");
      this.wazeniaService.dodajWazenie(przyjecie).subscribe({
          next: response => {
            this.przyjecieFormGroup.get('notatka.id').setValue(`${response.id}`); 
            // alert(`Dodano ważenie.\ni: ${response.id}`);


            // reset cart
            // this.resetCart();

          },
          error: err => {
            alert(`There was an error: ${err.message}`);
          }
        }
      );
    }else{
        console.log("Edytuj rekord");
        console.log(JSON.stringify(przyjecie));
        this.wazeniaService.edytujWazenie(przyjecie).subscribe({
          next: response => {
            console.log(JSON.stringify(response));
          //  this.przyjecieFormGroup.get('notatka.id').setValue(`${response.id}`); 
            // alert(`Zmiany zapisano.\nId: ${response.id}`);


            // reset cart
            // this.resetCart();

          },
          error: err => {
            alert(`There was an error: ${err.message}`);
          }
        }
      );
    }

    
    if( event.submitter.name == "save_and_close" ){
        const url=`rozpoczete/${this.currentKomorkaOrganizacyjnaSymbol}/${this.currentFiltrDzien}`;
        console.log('save_and_close: ' + url); 
        this.router.navigate([url]);

        // this.router.navigate([url]).then(() => {
        //   window.location.reload();
        // });
        
      }
    else if( event.submitter.name == "save_and_continue"){
      const url=`rozpoczete/${this.currentKomorkaOrganizacyjnaSymbol}/${this.currentFiltrDzien}`; 
      console.log('save_and_continue: ' + url);
      this.router.navigate([url]);

      // this.router.navigate([url]).then(() => {
      //   window.location.reload();
      // });
      
    }
    else if( event.submitter.name == "reset")
      { console.log('reset'); }

  }

  redirectTo(uri:string){
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    // this.router.navigate([uri]));

    this.router.navigate([uri]).then(()=>
          window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
      })
    );
  }

  handleSearchAdres() {
    // const szukaj = this.przyjecieFormGroup.get('szukaj.szukajAdresu').value;    


    const miejscowosc = this.przyjecieFormGroup.get('szukaj.szukajMiejscowosc').value;
    const ulica = this.przyjecieFormGroup.get('szukaj.szukajUlica').value;
    const nr = this.przyjecieFormGroup.get('szukaj.szukajNrDomu').value;

    if(miejscowosc.length>0 || ulica.length>0 || nr.length>0){
      // console.log("handleSearchAdres()" + miejscowosc + " " + ulica + " " + nr);
      this.deklaracjeService.szukajMiejscowoscUlicaNr(miejscowosc, ulica, nr).subscribe(this.processResult());
      // console.log(this.listaAdresow);      
    }else{
      this.listaAdresow = [];
    }

    
  }

  handleSumujWazenia(){   
      // const szukaj = this.przyjecieFormGroup.get('szukaj.szukajAdresu').value;    
  
      console.log("handleSearchAdres()");
      
      const gmina=encodeURIComponent(this.przyjecieFormGroup.get('mieszkaniec.gmina').value); 
      const miejscowosc=encodeURIComponent(this.przyjecieFormGroup.get('mieszkaniec.miejscowosc').value); 
      const ulica=encodeURIComponent(this.przyjecieFormGroup.get('mieszkaniec.ulica').value); 
      const nrDomu=encodeURIComponent(this.przyjecieFormGroup.get('mieszkaniec.nrDomu').value); 
      const rodzajSelekcji=encodeURIComponent(this.przyjecieFormGroup.get('selekcja.rodzajSelekcji').value);
     
  
      if(gmina.length>0 && miejscowosc.length>0 && ulica.length>0 && nrDomu.length>0){
        console.log("handleSearchAdres()" + gmina + " " + miejscowosc + " " + ulica + " " + nrDomu);
        this.wazeniaService.getSumaWazenList(gmina, miejscowosc, ulica, nrDomu, this.odDnia, this.doDnia).subscribe(this.processResultSumaWazen());
        // console.log(this.listaAdresow);      
      }else{
        this.listaAdresow = [];
      }
  }

  // Uzupełnienie adresu na podstawie deklaracji
  completeAdres(adres:Deklaracja){
    this.przyjecieFormGroup.get('mieszkaniec.miejscowosc').setValue(adres.miejscowosc);
    this.przyjecieFormGroup.get('mieszkaniec.ulica').setValue(adres.ulica);
    this.przyjecieFormGroup.get('mieszkaniec.nrDomu').setValue(adres.nrDomu);
    this.przyjecieFormGroup.get('mieszkaniec.wlasciciel').setValue(adres.wlasciciel);
    this.przyjecieFormGroup.get('mieszkaniec.czyFirma').setValue(adres.czyFirma);
    this.przyjecieFormGroup.get('notatka.deklaracja').setValue(adres.uwagi);


    switch(adres.gmina){
      case 'Miasto Zamość':{
        this.przyjecieFormGroup.get('mieszkaniec.gmina').setValue('miasto Zamość');
        break;
      }
      case 'Gmina Zamość':{
        this.przyjecieFormGroup.get('mieszkaniec.gmina').setValue('Zamość');
        break;
      }
      case 'Gmina Skierbieszów':{
        this.przyjecieFormGroup.get('mieszkaniec.gmina').setValue('Skierbieszów');
        break;
      }
      case 'Gmina Łabunie':{
        this.przyjecieFormGroup.get('mieszkaniec.gmina').setValue('Łabunie');
        break;
      }
      default: { 
        this.przyjecieFormGroup.get('mieszkaniec.gmina').setValue('');
      }
    }
    console.log("completeAdres -> Sumowanie ");    
    this.handleSumujWazenia();
    console.log(JSON.stringify(this.listaSumaWazen));

  }

  processResult() {   
    return data => {
      this.listaAdresow = data;      
    };
  }
  processResultSumaWazen() {   
    return data => {
      console.log(data);
      this.listaSumaWazen = data;      
    };
  }

  
}
function ConvertStringToNumber(input: string) {     
  if (!input) return NaN;

  if (input.toString().trim().length==0) { 
      return NaN;
  }
  return Number(input);
}

