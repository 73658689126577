<div class="page-wrapper">

  <!-- MENU SIDEBAR-->
  <!-- <aside class="menu-sidebar d-none d-lg-block" >
    <div class="logo">
      <a routerLink="/products">
        <img src="assets/logo.png" alt="logo" class="img-responsive">
      </a>
    </div>
    
  </aside> -->
  <!-- END MENU SIDEBAR -->

  <div class="page-container">

     <!-- <app-search></app-search> -->
 
            <!-- <app-cart-status></app-cart-status> -->
    <!-- HEADER DESKTOP-->
    <!--
    <header class="header-desktop">
    
      <div class="section-content section-content-p30">
        <div class="container-fluid">
          <div class="header-wrap">

           
           

          </div>
          <div class="account-wrap"></div>
        </div>
      </div>
    </header>
  -->
    <!-- END HEADER DESKTOP-->

    <!-- MAIN CONTENT-->
    <router-outlet></router-outlet>

  </div>
</div>

<!-- ENDS PAGE CONTAINER -->

<footer>
  <app-pszok-menu></app-pszok-menu>
  
  <p>
    <button class="btn btn-success mr-1" (click)='login()'>login</button>
    <button class="btn btn-primary mr-1" (click)='logout()'>logout</button>
    <button class="btn btn-link mr-1" (click)='logoutExternally()'>logout externally...</button>
  </p>
  <p>
    <button class="btn btn-warning mr-1" (click)='refresh()'>force silent refresh</button>
    <button class="btn btn-secondary mr-1" (click)='reload()'>reload page</button>
    <button class="btn btn-danger mr-1" (click)='clearStorage()'>clear storage</button>
  </p>
  <hr>
  <table class="table table-bordered table-sm table-props">
    <tr><th>Logo</th><td><img src="assets/logo.png" alt="logo" class="img-responsive"></td></tr>
    <tr><th>IsAuthenticated</th><td><code id="isAuthenticated">{{isAuthenticated$ | async}}</code></td></tr>
    <!-- <tr><th>HasValidToken</th><td><code id="hasValidToken">{{hasValidToken}}</code></td></tr> -->
    <!-- <tr><th>Username</th><td><code class="break-all"><div *ngIf="isAuthenticated$">{{ identityClaims["preferred_username"] }}</div></code></td></tr> -->
    <!-- <tr><th>IsDoneLoading</th><td><code id="isDoneLoading">{{isDoneLoading$ | async}}</code></td></tr> -->
    <!-- <tr><th>CanActivateProtectedRoutes</th><td><code id="canActivateProtectedRoutes">{{canActivateProtectedRoutes$ | async}}</code></td></tr> -->
    <!-- <tr><th>IdentityClaims</th><td class="pre"><code id="identityClaims">{{identityClaims | json}}</code></td></tr>
    <tr><th>RefreshToken</th><td><code class="break-all">{{refreshToken}}</code></td></tr>
    <tr><th>AccessToken</th><td><code class="break-all">{{accessToken}}</code></td></tr>
    <tr><th>IdToken</th><td><code class="break-all">{{idToken}}</code></td></tr> -->
  </table>
</footer>