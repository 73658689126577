import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OdpadySelektywnieZebrane } from 'src/app/common/odpady-selektywnie-zebrane';
import { WazenieService } from 'src/app/services/wazenie.service';

@Component({
  selector: 'app-wazenie-list',
  templateUrl: './rozpoczete-list.component.html',
  styleUrls: ['./rozpoczete-list.component.css']
})
export class RozpoczeteListComponent implements OnInit {
  
  lastUpdateTime: Date = null; 
  wazenia: OdpadySelektywnieZebrane[] = [];
  currentKomorkaOrganizacyjnaSymbol: string = 'PSZOK1';
  previousKomorkaOrganizacyjnaSymbol: string = 'PSZOK1';

  currentFiltrDzien: string = new Date().toISOString().substring(0,10);
  previousFiltrDzien: string = new Date().toISOString().substring(0,10);
  
  // searchMode: boolean = false;

  // new properties for pagination
  thePageNumber: number = 1;
  thePageSize: number = 5;
  theTotalElements: number = 0;

  private intervalId;

  // previousKeyword: string = null;

  constructor(private wazenieService: WazenieService,    
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    // this.router.events.pipe(
    //   filter((event: RouterEvent) => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   this.listWazenia();
    // });
    this.intervalId = setInterval(()=>{
      this.checkLastUpdateTime();
    },9000);
    this.route.paramMap.subscribe(() => {
      this.listWazenia();      
    });

    
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  checkLastUpdateTime(){
      this.wazenieService.getLastUpdateTime()
      .subscribe(czas=>{
        if(this.lastUpdateTime === czas){
          //nic sie nie zmieniło
        }else{
          this.lastUpdateTime = czas;
          this.listWazenia();
        }
        
      });
  }

  listWazenia() {
    this.handleListWazenia();
  }
  handleListWazenia() {
   
    const hasKomorkaOrganizacyjnaSymbol: boolean = this.route.snapshot.paramMap.has('komorkaOrganizacyjnaSymbol');
    if (hasKomorkaOrganizacyjnaSymbol) {      
      this.currentKomorkaOrganizacyjnaSymbol = this.route.snapshot.paramMap.get('komorkaOrganizacyjnaSymbol');
    }
    else {      
      this.currentKomorkaOrganizacyjnaSymbol = 'PSZOK1';
    }


    const hasFiltrDzien: boolean = this.route.snapshot.paramMap.has('filtrDzien');
    if (hasFiltrDzien) {      
      this.currentFiltrDzien = this.route.snapshot.paramMap.get('filtrDzien');
    }
    else {      
      this.currentFiltrDzien = new Date().toISOString().substring(0,10);
    }

    
    if (this.previousKomorkaOrganizacyjnaSymbol != this.currentKomorkaOrganizacyjnaSymbol) {
      this.thePageNumber = 1;
    }

    this.previousKomorkaOrganizacyjnaSymbol = this.currentKomorkaOrganizacyjnaSymbol;


    if (this.previousFiltrDzien != this.currentFiltrDzien) {
      this.thePageNumber = 1;
    }

    this.previousFiltrDzien = this.currentFiltrDzien;

    // console.log(`currentKomorkaOrganizacyjnaSymbol=${this.currentKomorkaOrganizacyjnaSymbol}, thePageNumber=${this.thePageNumber}`);

    // console.log(`currentFiltrDzien=${this.currentFiltrDzien}, thePageNumber=${this.thePageNumber}`);
    // now get the products for the given category id
    // console.log(`${this.thePageNumber}, ${this.thePageSize}`)
    this.wazenieService.getRozpoczeteListPaginate(this.thePageNumber - 1,
                                               this.thePageSize,
                                               this.currentKomorkaOrganizacyjnaSymbol,
                                               this.currentFiltrDzien)
                                               .subscribe(this.processResult());
  }

  processResult() {
    return data => {
      this.wazenia = data.content;      
      this.thePageNumber = data.number + 1;
      this.thePageSize = data.size;
      this.theTotalElements = data.totalElements;
      // console.log(`${this.thePageNumber} / 
      //   ${this.thePageSize} z 
      //   ${this.theTotalElements}`);
        
    };
  }

  updatePageSize(pageSize: number) {
    this.thePageSize = pageSize;
    this.thePageNumber = 1;
    this.listWazenia();
  }

  refreshPage() {
    window.location.reload();
   }
  

}
