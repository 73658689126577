import { Component, OnInit } from '@angular/core';
import { KomorkaOrganizacyjna } from 'src/app/common/komorka-organizacyjna';
import { KomorkaOrganizacyjnaService } from 'src/app/services/komorka-organizacyjna.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Component({
  selector: 'app-pszok-menu',
  templateUrl: './pszok-menu.component.html',
  styleUrls: ['./pszok-menu.component.css']
})
export class PszokMenuComponent implements OnInit {

  pszoki: KomorkaOrganizacyjna[];

  filtrDzien: string;

 

  constructor(private komorkaOrganizacyjnaService: KomorkaOrganizacyjnaService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.listPszoki();    
    this.updateFilterDzien() ;  
    // console.log(`Data ${this.filtrDzien}`)
  }
  listPszoki() {
    this.komorkaOrganizacyjnaService.getPszoki().subscribe(
      data => {
        // console.log('PSZOKi: ' + JSON.stringify(data));
        this.pszoki=data;
      }
    )
  }

  doSearch(value: string) {
    var pszok: string;
    const hasKomorkaOrganizacyjnaSymbol: boolean = this.route.snapshot.paramMap.has('komorkaOrganizacyjnaSymbol');
    if (hasKomorkaOrganizacyjnaSymbol) {      
      pszok= this.route.snapshot.paramMap.get('komorkaOrganizacyjnaSymbol');
    }
    else {      
      pszok = 'PSZOK1';
    }
    this.komorkaOrganizacyjnaService.updateFilterDzien(value);    
    // console.log(`value=${value}`);
    this.router.navigateByUrl(`/wazenia/PSZOK1/${value}`);
  }

  updateFilterDzien() {

    // subscribe to the cart totalPrice
    this.komorkaOrganizacyjnaService.filtrDzienSubject.subscribe(
      data => this.filtrDzien = data
    );
    
  }


  onSubmit(): void {
    // Process checkout data here
    // this.items = this.cartService.clearCart();
    // console.warn('Your order has been submitted', this.checkoutForm.value);
    // this.checkoutForm.reset();
  }

}
